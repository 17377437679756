import React, { ReactElement } from 'react';
import { classNames } from '../../utils/style';
import { DefaultBsComponent } from '../../types/module';
import { Rating } from '../Rating';
import {
  fontBodyMini,
  fontCaption,
  fontMegaHeading,
} from '../../styles/typographyNew';

export type ConversionBannerProps = DefaultBsComponent & {
  rating?: number;
  ratingText?: string;
  title?: string;
  description?: string;
  buttons?: ReactElement[];
};
export const ConversionBanner = ({
  as: Component = 'div',
  rating,
  ratingText,
  title,
  description,
  buttons,
  className,
  ...props
}: ConversionBannerProps) => {
  return (
    <Component
      {...props}
      className={classNames('ConversionBanner', 'relative', className)}
    >
      <div
        className={classNames(
          'z-20 relative',
          'bg-backgroundConversionBanner',
          'rounded-[40px] md:rounded-[60px]',
          'mt-20',
          'py-[118px] px-[20px]',
          'md:py-[100px] md:px-[179px]',
          'shadow-conversionBanner',
        )}
      >
        {rating && (
          <div
            className={classNames(
              fontBodyMini,
              'text-[#EBF4FF]',
              'm-auto flex items-center justify-center',
            )}
          >
            <Rating
              rating={rating}
              className={classNames('inline-block align-middle mr-[10px]')}
            />{' '}
            {rating}/5
            {ratingText && ` ${ratingText}`}
          </div>
        )}
        {title && (
          <h2
            className={classNames(
              fontMegaHeading,
              'text-[#FFFFFF] max-w-1000 mx-auto',
              'text-center',
              !!rating && 'mt-24 md:mt-32',
              ((buttons?.length || 0) > 0 || description) && 'mb-24 md:mb-32',
            )}
          >
            {title}
          </h2>
        )}
        {(buttons?.length || 0) > 0 && (
          <div className="flex flex-col sm:flex-row justify-center gap-12">
            {buttons}
          </div>
        )}
        {description && (
          <p
            className={classNames(
              fontCaption,
              'text-center text-[#EBF4FF]',
              (buttons?.length || 0) > 0 && 'mt-24',
            )}
          >
            {description}
          </p>
        )}
      </div>
      <div
        className={classNames(
          'absolute z-10',
          'bg-[#89BEFA] rounded-[40px] md:rounded-[60px]',
          '-top-[10px] h-[120px]',
          'w-[calc(100%-10px*2)] left-[10px]',
          'md:w-[calc(100%-20px*2)] md:left-[20px]',
          'shadow-conversionBanner',
        )}
      />
      <div
        className={classNames(
          'absolute z-0',
          'bg-[#D6E9FF] rounded-[40px] md:rounded-[60px]',
          'w-[calc(100%-20px*2)] left-[20px]',
          'md:w-[calc(100%-40px*2)] md:left-[40px]',
          'shadow-conversionBanner h-[120px] -top-[20px]',
        )}
      />
    </Component>
  );
};
