import { useEffect, useState } from 'react';

export const useCountWords = (ref: React.RefObject<any>) => {
  const [numberWords, setNumberWords] = useState(0);

  useEffect(() => {
    if (ref.current) {
      const html = ref.current.innerHTML;
      const text: string = html.replace(/<[^>]*>/g, ''); // Remove HTML tags
      const words = text.split(/\s+/).filter((word) => word !== '');
      setNumberWords(words.length);
    }
  }, [ref]);

  return numberWords;
};
