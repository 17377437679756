import { ButtonV2 } from 'libs/growth-platform-brand-system-v2/src/components/ButtonV2';
import { ConversionBanner } from 'libs/growth-platform-brand-system-v2/src/components/ConversionBanner';
import { classNames } from 'libs/growth-platform-brand-system-v2/src/utils/style';
import { ButtonTracking } from 'libs/growth-platform-brand-system-v2/src/utils/tracking/ButtonTracking';
import { morphism } from 'morphism';
import React from 'react';
import {
  DatoButtonProps,
  schemaDatoButton,
} from '../../morphismSchema/schemaDatoButton';

export const ConversionBannerComponent = ({
  block,
  locale,
  sectionId,
  classname,
}: any) => {
  const { buttons, ..._block }: { buttons: any[]; _blocks: any } = block;

  const _buttons: DatoButtonProps[] | undefined = morphism(
    schemaDatoButton,
    buttons?.map((button: any) => ({ ...button, locale })),
  );

  return (
    <div className={classNames('mx-16 md:mx-32', classname)}>
      <ConversionBanner
        {..._block}
        buttons={_buttons?.map((button, key) => {
          return (
            <ButtonTracking
              key={key}
              {...button}
              component={ButtonV2}
              className={classNames('w-full mx-auto', 'sm:w-fit sm:mx-0')}
              labelTracking={button?.title}
              section={sectionId}
              index={key}
            />
          );
        })}
        className="w-full m-auto"
      />
    </div>
  );
};
export default ConversionBannerComponent;
